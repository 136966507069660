
import path from 'path';
export default {
    name: 'MyMenus',
    props: {
        menuData: Object,
        defaultActive: {
            type: String,
            default: '1-1'
        },
        isCollapse: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        activeMenu() {
            const route = this.$route;
            const { meta, path } = route;
            if (meta.activeMenu) {
                return meta.activeMenu;
            }
            return path;
        }
    },
    data() {
        return {
            form: {
                oldPassword: '',
                newPassword: '',
                newPassword2: ''
            },
            centerDialogVisible: false,
            role: 1,
            fullscreenLoading: false, //全屏Loading
            passwordDialogVisible: false, //修改密码
            infoVisible: false, //个人中心模态框是否显示
            isFullScreen: false, //是否全屏
            active: '',
            basePath: '',
            routes: [],
            tmpPath: null,
            showMenusFlag: false,
            isFold: false,
            userInfo: {},
            switchClass: true
        };
    },
    activated() {
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        this.username = this.userInfo.username || '匿名用户';
        this.nickname = this.userInfo.nickname;
        this.routes = [];
        this.tmpPath = null;
        this.renderingMenu();
    },
    beforeUpdate() {
        this.tmpPath = null;
    },
    methods: {
        //操作修改密码
        handleEditPassword() {
            if (this.form.newPassword != this.form.newPassword2) {
                this.$message.error('抱歉，您两次输入的新密码不一致');
                return false;
            }
            var json = {
                oldPassword: this.form.oldPassword,
                newPassword: this.form.newPassword
            };
            let _this = this;
            this.instance.post('/upms/api/user/changePassword', json).then((res) => {
                var data = res.data;
                if (data.code == 200) {
                    this.$message.success(data.message);
                    setTimeout(() => {
                        _this.centerDialogVisible = false;
                        _this
                            .$confirm('密码修改成功，请重新登录。', '提示', {
                                confirmButtonText: '确定',
                                showCancelButton: false,
                                type: 'warning'
                            })
                            .then(() => {
                                let localVueVersion = localStorage.getItem('vueVersion');
                                localStorage.clear();
                                localStorage.setItem('vueVersion', localVueVersion);
                                _this.$router.replace({
                                    path: '/'
                                });
                            });
                    }, 3000);
                } else {
                    this.$message.error(data.message);
                }
            });
        },
        //操作退出
        handleLogOut() {
            this.$confirm('此操作将退出当前登录, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.instance.post('/upms/api/user/logout').then((res) => {
                        var data = res.data;
                        if (data.code == 200) {
                            let localVueVersion = localStorage.getItem('vueVersion');
                            localStorage.clear();
                            localStorage.setItem('vueVersion', localVueVersion);
                            sessionStorage.clear();
                            this.$router.push({ path: '/' });
                            this.$message({ type: 'success', message: '退出成功!' });
                        }
                    });
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
        },
        //点击面包屑，是否展开菜单
        handleFoldMenus() {
            let _this = this;
            this.isFold = !this.isFold;
            if (this.isFold) {
                setTimeout(() => {
                    _this.switchClass = false;
                }, 330);
            } else {
                setTimeout(() => {
                    _this.switchClass = true;
                }, 300);
            }
            this.$emit('handleFoldMenus', this.isFold);
        },
        linkIndexKey(child, parent) {
            let _path = parent + '/' + child.path;
            if (child.children) {
                _path = parent + '/' + child.children[child.children.length - 1].path;
            } else {
                _path = parent.path + '/' + child.path;
            }
            return _path;
        },
        linkProps(child, parent) {
            if (child.meta && child.meta.menuId && child.meta.title) {
                this.recordEventTracking(child.meta.menuId, null, child.meta.title);
            }

            let _path = parent + '/' + child.path;
            if (child.children) {
                _path = parent + '/' + child.children[child.children.length - 1].path;
            } else {
                _path = parent.path + '/' + child.path;
            }
            if (_path != this.tmpPath) {
                // 在这里执行清理任务，例如移除事件监听器等
                localStorage.removeItem('queryFromCondition');
                localStorage.removeItem('queryFromPage');

                this.$router.push({
                    path: _path,
                    query: {
                        t: +new Date() //保证每次点击路由的query项都是不一样的，确保会重新刷新view
                    }
                });
                this.tmpPath = _path;
            }
        },
        resolvePath(basePath, routePath) {
            return path.resolve(basePath, routePath);
        },
        renderingMenu() {
            let itemTimer = setInterval(() => {
                this.showMenusFlag = false;
                if (localStorage.getItem('routes')) {
                    clearInterval(itemTimer);
                    this.routes = JSON.parse(localStorage.getItem('routes'));
                    this.showMenusFlag = true;
                }
            }, 50);
        }
    },
    updated() {
        if (this.$route.path == '/') {
            this.tmpPath = '/';
        }
    }
};
